/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.p-datatable-wrapper{
  overflow: visible !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 0 0 / 54%) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #000 !important;
}

.bg-naranja {
  background-color: #f44b2d !important;
}
table {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fff !important;
  width: 100%;
}
.mat-progress-spinner,
.mat-progress-spinner svg {
  width: 100% !important;
  height: 36px !important;
}

.p-inplace .p-inplace-display{
  display: block !important;
}

.p-button{
  background-color: #2a2a2a !important;
}

.p-button:enabled:hover{
  background-color: rgba(42, 42, 42, 0.92) !important;
}

.p-button:enabled:active{
  background-color: rgba(42, 42, 42, 0.68) !important;
}

.p-button-secondary{
  background-color: #f44b2d !important;
}

.p-button-secondary:enabled:hover{
  background-color: rgba(244, 76, 47, 0.92) !important;
}

.p-button-secondary:enabled:active{
  background-color: rgba(244, 76, 47, 0.68) !important;
}

.p-speeddial{
  position: static !important
}

.p-speeddial-action{
  background-color: #f44b2d !important;
}

.p-speeddial-action:hover{
  background-color: rgba(244, 76, 47, 0.92) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight{
  border-color: #2a2a2a !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
  background-color: #2a2a2a !important;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button span{
  color: #fff !important;
}

.pac-container{
  z-index: 1200 !important;
}